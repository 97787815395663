import {
  apiCreateLinkToken,
  apiFetchCandidateJobName,
  apiFetchIntegrations,
  apiRetrieveAccountToken,
  apiSearchCandidates,
  apiSearchEmployees,
} from '@/offers/api/offers-common';

const state = {
  linkToken: '',
  accountToken: '',
  integrations: [],
  candidatesSearch: [],
  employeesSearch: [],
  selectedCandidateJob: '',
};

const getters = {
  getLinkToken: (state) => state.linkToken,
  getAccountToken: (state) => state.accountToken,
  getIntegrations: (state) => state.integrations,
  getCandidatesSearch: (state) => state.candidatesSearch,
  EmployeesSearch: (state) => state.employeesSearch,
  getSelectedCandidateJob: (state) => state.selectedCandidateJob,
};

const mutations = {
  setLinkToken(state, payload) {
    state.linkToken = payload;
  },
  setAccountToken(state, payload) {
    state.accountToken = payload;
  },
  setIntegrations(state, payload) {
    state.integrations = payload;
  },
  setCandidatesSearch(state, payload) {
    state.candidatesSearch = payload;
  },
  setEmployeesSearch(state, payload) {
    state.employeesSearch = payload;
  },
  setSelectedCandidateJob(state, payload) {
    state.selectedCandidateJob = payload;
  },
};

const actions = {
  createLinkToken(context) {
    return apiCreateLinkToken()
      .then((response) => {
        context.commit('setLinkToken', response.data.link_token);
      })
      .catch((e) => {});
  },

  retrieveAccountToken(context, publicToken) {
    apiRetrieveAccountToken({ params: { public_token: publicToken } })
      .then((response) => {
        context.commit('setAccountToken', response.data.account_token);
      })
      .catch((e) => {});
  },

  fetchIntegrations(context) {
    return apiFetchIntegrations()
      .then((response) => {
        context.commit('setIntegrations', response.data);
      })
      .catch((e) => {});
  },

  searchCandidates(context, params) {
    apiSearchCandidates({ params })
      .then((response) => {
        context.commit('setCandidatesSearch', response.data);
      })
      .catch((e) => {});
  },

  fetchCandidateJobName(context, param) {
    return apiFetchCandidateJobName({ params: param })
      .then((response) => {
        context.commit('setSelectedCandidateJob', response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  },

  searchEmployees(context, data) {
    apiSearchEmployees({ params: { filter: data.filter, pageSize: data.pageSize } })
      .then((response) => {
        context.commit('setEmployeesSearch', response.data);
      })
      .catch((e) => {});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
