<template>
  <div class="flex h-full flex-col overflow-y-auto px-6">
    <div>
      <PAlert v-if="isTicketCreated" type="success" class="mt-6">
        <p>
          <span class="text-xs font-bold">Thank you for contacting us! </span>
          <span class="text-xs font-medium">We will be in touch shortly.</span>
        </p>
      </PAlert>
      <div class="py-6">
        <h3 class="text-2xl font-semibold">Hi {{ firstName }}, how can we help?</h3>
      </div>
      <div>
        <div class="mb-6 rounded-2xl border border-p-gray-30 p-6 hover:bg-p-blue-10">
          <PLink to="https://getpequity.zendesk.com/hc/en-us" target="_blank">
            <div class="flex items-center">
              <div>
                <div class="mb-2.5">
                  <img src="@/help/assets/knowledge-base-icon.svg" />
                </div>
                <div>
                  <h4 class="pb-0.5 font-semibold">Visit our knowledge base</h4>
                  <p class="text-sm opacity-50">Explore our full collection of help and support resources.</p>
                </div>
              </div>
              <div>
                <img src="@/help/assets/share-external-link-icon.svg" />
              </div>
            </div>
          </PLink>
        </div>
        <div
          class="mb-6 cursor-pointer rounded-2xl border border-p-gray-30 p-6 hover:bg-p-blue-10"
          @click="changeFormMode('support')"
        >
          <div class="mb-2.5">
            <img src="@/help/assets/contact-support-icon.svg" />
          </div>
          <div>
            <h4 class="pb-0.5 font-semibold">Contact support</h4>
            <p class="text-sm opacity-50">
              Our support team is here to help answer your questions, report bugs, take your feedback and ideas to
              improve Pequity.
            </p>
          </div>
        </div>

        <div
          v-if="isAdmin && !isFreeTier"
          class="cursor-pointer rounded-2xl border border-p-gray-30 p-6 hover:bg-p-blue-10"
          @click="changeFormMode('compensation')"
        >
          <div class="mb-2.5">
            <img src="@/help/assets/comp-expect-icon.png" />
          </div>
          <div>
            <h4 class="pb-0.5 font-semibold">Ask a Compensation Expert</h4>
            <p class="text-sm opacity-50">
              Have a specific question about compensation and want to discuss with one of our Compensation Experts? Send
              us a message and we’ll help out!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PAlert from '@squirrel/components/p-alert/p-alert.vue';
import { mapGetters } from 'vuex';
import PLink from '@/squirrel/components/p-link/p-link.vue';

export default {
  name: 'HelpMain',
  components: { PAlert, PLink },
  props: {
    isTicketCreated: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['form-mode-changed'],
  computed: {
    ...mapGetters('auth', ['getProfile']),
    ...mapGetters('companies', ['isFreeTier']),
    firstName() {
      return this.getProfile?.first_name || '';
    },
    isAdmin() {
      return this.getProfile.is_admin;
    },
  },
  methods: {
    changeFormMode(mode) {
      this.$emit('form-mode-changed', mode);
    },
  },
};
</script>

<style scoped></style>
