<template>
  <div :class="[{ hidden: $attrs.hidden }]" :data-has-error="!!errorMsg">
    <slot name="label" :label="label" :label-classes="labelClasses">
      <label v-if="label" :class="labelClasses">
        {{ label }}
      </label>
    </slot>
    <div class="relative w-full">
      <div v-if="!!$slots.prefix">
        <slot name="prefix"></slot>
      </div>
      <input
        :type="type"
        :value="value"
        v-bind="$attrs"
        :class="inputClasses"
        v-on="{ ...$listeners, input: (event) => $emit('input', event.target.value) }"
      />
      <div v-if="!!$slots.suffix">
        <slot name="suffix"></slot>
      </div>
    </div>
    <div v-show="errorMsg" :class="errorMsgClasses">{{ errorMsg }}</div>
  </div>
</template>

<script>
import inputClassesMixin from '@squirrel/utils/inputClassesMixin';

const ALLOWED_TYPES = { TEXT: 'text', PASSWORD: 'password' };

export default {
  name: 'PInput',
  mixins: [inputClassesMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: ALLOWED_TYPES.TEXT,
      validator(value) {
        return Object.values(ALLOWED_TYPES).includes(value);
      },
    },
    label: {
      type: String,
      default: '',
    },
    errorMsg: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
};
</script>
