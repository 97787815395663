<template>
  <div :class="[{ hidden: $attrs.hidden }]" :data-has-error="!!errorMsg">
    <slot name="label" :label="label" :label-classes="labelClasses">
      <label v-if="label" :class="labelClasses">
        {{ label }}
      </label>
    </slot>
    <textarea
      :value="value"
      v-bind="$attrs"
      :class="textareaClasses"
      v-on="{ ...$listeners, input: (event) => $emit('input', event.target.value) }"
    >
    </textarea>
    <div v-show="errorMsg" :class="errorMsgClasses">{{ errorMsg }}</div>
  </div>
</template>

<script>
import inputClassesMixin from '@squirrel/utils/inputClassesMixin';

export default {
  name: 'PTextarea',
  mixins: [inputClassesMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    errorMsg: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
};
</script>
