const state = {
  showHelpDrawer: false,
};
const mutations = {
  toggleDrawer(state) {
    state.showHelpDrawer = !state.showHelpDrawer;
  },
};
const getters = {
  showHelpDrawer(state) {
    return state.showHelpDrawer;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
