import axios from 'axios';
import { API_URL } from '@/helpers';

export default axios.create({
  baseURL: API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});
