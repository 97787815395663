import DOMPurify from 'dompurify';

export const LOCATION_DELIMITER = ',';

export const makeJobRanges = (job = {}) => ({
  job_id: job.id || null,
  job_name: job.name || null,
  job_link: job.link || null,
  ranges: {},
});

export const makeManualRange = (range = {}) => ({
  id: range.id || null,
  locations: range.location || null,
  salary_high: range.salary_high ? `${range.salary_high}` : null,
  salary_low: range.salary_low ? `${range.salary_low}` : null,
});

export const rangesToAddInit = () => makeJobRanges();
export const rangesToAddErrorsInit = () => makeJobRanges();

const requiredString = (raw, formatter, invalidMessage = 'Invalid value') => {
  return raw === null ? null : raw === '' ? 'Cannot be empty' : formatter(raw) === '' ? invalidMessage : null;
};

const optionalString = (raw, formatter, invalidMessage = 'Invalid value') => {
  return raw === null || raw === '' ? null : formatter(raw) === '' ? invalidMessage : null;
};

const requiredNumber = (raw, formatter, invalidMessage = 'Invalid amount') => {
  return raw === null ? null : raw === '' ? 'Cannot be empty' : isNaN(formatter(raw)) ? invalidMessage : null;
};

const formatters = {
  job_id: (id) => parseInt(id, 10),
  job_name: (name) => DOMPurify.sanitize(name),
  job_link: (link) => {
    try {
      const input = link.trim();
      const prefix = /^https{0,1}:\/\//.test(input) ? '' : 'https://';
      const url = `${prefix}${input}`;
      const clean = DOMPurify.sanitize(url);
      if (url !== clean) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-throw-literal
        throw 'invalid';
      }
      return new URL(clean).toString();
    } catch {
      return '';
    }
  },
  ranges: {
    locations: (location) => DOMPurify.sanitize(location),
    salary_high: (high) => parseFloat(DOMPurify.sanitize(high)),
    salary_low: (low) => parseFloat(DOMPurify.sanitize(low)),
  },
};

export const serializeRanges = (job) => {
  if (!job) {
    return {};
  }
  const { job_name, job_link, ranges } = job;
  const job_id = job?.job_id;
  const rangesByLocation = Object.values(ranges).reduce((acc, { locations, salary_high, salary_low }) => {
    locations?.split?.(LOCATION_DELIMITER)?.forEach?.((locale) => {
      if (!locale?.trim?.()) {
        return;
      }
      acc[formatters.ranges.locations(locale?.trim())] = {
        salary_low: formatters.ranges.salary_low(salary_low?.trim()),
        salary_high: formatters.ranges.salary_high(salary_high?.trim()),
      };
    });
    return acc;
  }, {});

  return {
    job_id: job_id ? formatters.job_id(job_id) : null,
    job_name: formatters.job_name(job_name?.trim()),
    job_link: formatters.job_link(job_link?.trim()),
    ranges: rangesByLocation,
  };
};

export const errorsFromRanges = (rangesToAdd, knownErrors) => {
  const errors = { ranges: {} };
  const { job_name, job_link, ranges } = rangesToAdd;

  errors.job_name = knownErrors.job_name || requiredString(job_name, formatters.job_name, `Invalid name`);
  errors.job_link = knownErrors.job_link || optionalString(job_link, formatters.job_link, 'Invalid url');

  Object.entries(ranges).forEach(([uuid, range]) => {
    const { locations, salary_high, salary_low } = range;

    errors.ranges[uuid] = {
      locations:
        knownErrors.ranges?.[uuid]?.locations ||
        requiredString(locations, formatters.ranges.locations, 'Invalid location(s)'),
      salary_high:
        knownErrors.ranges?.[uuid]?.salary_high || requiredNumber(salary_high, formatters.ranges.salary_high),
      salary_low: knownErrors.ranges?.[uuid]?.salary_low || requiredNumber(salary_low, formatters.ranges.salary_low),
    };
  });

  return errors;
};

const isBlank = (value) => value === null || value === '';

export const hasRequired = ({ job_name, ranges }) => {
  if (isBlank(job_name)) {
    return false;
  }
  for (const uuid in ranges) {
    for (const attr in ranges[uuid]) {
      // 'id' shouldn't be required field because it will be null if we
      // add a new range to the job
      if (attr === 'id') {
        continue;
      }

      if (isBlank(ranges[uuid][attr])) {
        return false;
      }
    }
  }

  return true;
};

export const hasErrors = (errors) => {
  if (!errors) {
    return false;
  }
  const { ranges, ...job } = errors;
  for (const attr in job) {
    if (job[attr]) {
      return true;
    }
  }
  for (const uuid in ranges) {
    for (const attr in ranges[uuid]) {
      if (ranges[uuid][attr]) {
        return true;
      }
    }
  }
  return false;
};

export const validRanges = (ranges) => {
  return Object.values(ranges).every(({ salary_low, salary_high }) => Number(salary_low) < Number(salary_high));
};
