<template>
  <PInput v-if="mode === 'input'" v-model="innerValue" v-bind="$attrs" v-on="$listeners">
    <template v-if="$attrs.maxlength" #label="{ label, labelClasses }">
      <div class="flex">
        <label v-if="label" :class="[labelClasses, 'flex-none']">{{ label }}</label>
        <div class="mb-3 flex w-full justify-end text-sm font-semibold text-p-gray-40">
          {{ charCountdown }}
        </div>
      </div>
    </template>
  </PInput>

  <PTextarea v-else-if="mode === 'text-area'" v-model="innerValue" rows="7" v-bind="$attrs" v-on="$listeners">
    <template v-if="$attrs.maxlength" #label="{ label, labelClasses }">
      <div class="flex">
        <label v-if="label" :class="[labelClasses, 'flex-none']">{{ label }}</label>
        <div class="mb-3 flex w-full justify-end text-sm font-semibold text-p-gray-40">
          {{ charCountdown }}
        </div>
      </div>
    </template>
  </PTextarea>
</template>

<script>
import PInput from '@squirrel/components/p-input/p-input.vue';
import PTextarea from '@squirrel/components/p-textarea/p-textarea.vue';

export default {
  name: 'HelpFormInput',
  components: {
    PTextarea,
    PInput,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    mode: {
      type: String,
      default: 'input',
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  computed: {
    charCountdown() {
      const charsLeft = this.$attrs.maxlength - this.innerValue.length || 0;

      return charsLeft > 0 ? charsLeft : 0;
    },
  },
  watch: {
    value(nV) {
      this.innerValue = nV;
    },
  },
};
</script>
