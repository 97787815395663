/**
 *
 * The ChunkLoadError occurs when the browser encounters an error in fetching some JavaScript files (chunks),
 * which were dynamically imported. When we release a new version of our app the previous chunks are deleted and replaced by new ones.
 * If the user has left a browser tab open while we released a new version, and he/she tries to navigate from route A to route B,
 * the browser will try to fetch the chunk that corresponds to that route. Since the file for route B does not exist anymore it
 * will produce a ChunkLoadError error. The user then needs to reload the browser tab in order to get the new version's JavaScript files.
 *
 * What we want to achieve is to provide the user with a seamless navigation experience while also reloading the page
 * so that the user's browser loads the new version's JavaScript files.
 *
 * How we handle it:
 *
 * - We store the route that the user tries to navigate to in every navigation (using the router's `beforeEach` hook).
 * - When we encounter the ChunkLoadError, we redirect the user to the new route using window.location.href,
 *   so this **reloads** the page. We also store a value in `localStorage` that indicates that a reload has taken place.
 *   We need to do that in order to ensure that this redirect happens only **once**.
 * - We clear the `localStorage` value in every successful navigation using the router's `afterEach` hook.
 *
 */

const STORAGE_KEY = 'pageForceRefreshed';

const pageHasAlreadyBeenRefreshed = () => window.localStorage.getItem(STORAGE_KEY) === 'true';

export const isChunkLoadError = (error) => {
  try {
    const errorDetails = JSON.parse(JSON.stringify(error));
    const errorName = errorDetails.name ?? errorDetails.code;
    return errorName === 'ChunkLoadError' || errorName === 'CSS_CHUNK_LOAD_FAILED';
  } catch (error) {
    return false;
  }
};

export function handleChunkLoadError(router) {
  let navigatingTo = null;

  router.beforeEach((to, from, next) => {
    navigatingTo = to;
    next();
  });

  router.afterEach((to, from) => {
    // After successful navigation, clear the localStorage value
    window.localStorage.setItem(STORAGE_KEY, 'false');
  });

  router.onError((error) => {
    // If after reloading the first time we have a chunk that is missing again we want to prevent a reload loop
    if (isChunkLoadError(error) && !pageHasAlreadyBeenRefreshed()) {
      window.localStorage.setItem(STORAGE_KEY, 'true');
      console.info('Application reload...');
      if (navigatingTo?.fullPath) {
        window.location.href = window.location.protocol + '//' + window.location.host + navigatingTo.fullPath;
      } else {
        window.location.reload();
      }
    }
  });
}
