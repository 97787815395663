import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';

const protocol = window.location.protocol + '//';
const split = window.location.host.split(':');
const newHost = split.length > 1 ? split[0] + ':8000' : split[0];
const urlBase = protocol + newHost;

const state = {
  marketData: [],
  sortedMarketDataRows: [],
  sources: [],
  cuts: [],
  loadingMarketData: true,
};

const getters = {
  getField,
  getMarketData: (state) => {
    return state.marketData;
  },
  getSortedMarketDataRows: (state) => {
    return state.sortedMarketDataRows;
  },
  getSources: (state) => state.sources,
  getCuts: (state) => state.cuts,
  getLoadingMarketData: (state) => {
    return state.loadingMarketData;
  },
};

const mutations = {
  updateField,
  setMarketData(state, data) {
    state.marketData = data.filter((row) => row.base_salary_5th !== 'N/A');
  },
  setSortedMarketDataRows(state, rows) {
    state.sortedMarketDataRows = rows.filter((row) => row.base_salary_5th !== 'N/A');
  },
  setSources(state, sources) {
    state.sources = sources;
  },
  setCuts(state, cuts) {
    state.cuts = cuts;
  },
  setLoadingMarketData(state, value) {
    state.loadingMarketData = value;
  },
};

const actions = {
  fetchMarketData(context, queryParam = '') {
    context.commit('setLoadingMarketData', true);
    axios.get(urlBase + `/api/impact-data?cut=${queryParam}`).then((response) => {
      context.commit('setMarketData', response.data);
      context.commit('setSortedMarketDataRows', response.data);
      context.commit('setLoadingMarketData', false);
    });
  },
  setSortedMarketDataRows(context, rows) {
    context.commit('setSortedMarketDataRows', rows);
  },
  setLoadingMarketData(context, value) {
    context.commit('setLoadingMarketData', value);
  },
  async createMarketData({ dispatch }, data) {
    await axios.post(urlBase + '/api/impact-data', data);
    dispatch('fetchMarketData');
  },
  async fetchSources({ commit }) {
    try {
      const { data } = await axios.get(urlBase + '/api/source');
      commit('setSources', data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchCuts({ commit }) {
    try {
      const { data } = await axios.get(urlBase + '/api/cut');
      commit('setCuts', data);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
