<template>
  <Portal v-if="mount" :selector="appendTo">
    <div
      v-if="outsideClickClose"
      class="fixed bottom-0 top-0 h-screen w-full"
      :style="{ 'z-index': zIndex - 1 }"
      tabindex="-1"
      @click="close"
    ></div>
    <Transition
      name="drawer-transition"
      :enter-active-class="transitionInClass"
      :leave-active-class="transitionOutClass"
      @before-enter="beforeOpen"
      @enter="opening"
      @after-enter="opened"
      @before-leave="beforeClose"
      @leave="closing"
      @after-leave="closed"
    >
      <div
        v-show="show"
        :data-drawer-id="id"
        tabindex="-1"
        :class="[drawerClass, position === 'right' ? 'drawer-right' : 'drawer-left']"
        :style="{ 'z-index': zIndex, ...drawerStyle }"
        role="dialog"
        :aria-label="title"
        aria-modal="false"
        :aria-describedby="`${id}-content`"
        :aria-labelledby="`${id}-title`"
      >
        <slot name="title-wrapper">
          <div
            :class="[
              'flex items-center border-b border-p-gray-30 px-6 pb-4 pt-4',
              { 'flex-row-reverse': position === 'right' },
            ]"
          >
            <PCloseBtn :aria-label="closeLabel" class="flex-0" @click.native="close" />
            <slot name="title">
              <h3
                v-if="title"
                :id="`${id}-title`"
                class="text-display-small flex-1"
                :class="{ 'text-right': position === 'left' }"
              >
                {{ title }}
              </h3>
            </slot>
          </div>
        </slot>
        <div v-if="errorMsg" class="mt-6 px-6">
          <PAlert type="error">{{ errorMsg }}</PAlert>
        </div>
        <slot name="content-wrapper">
          <div
            :class="[
              'relative mt-6 grow overflow-y-auto overflow-x-hidden px-6',
              { 'pointer-events-none opacity-50': disabled },
            ]"
          >
            <slot></slot>
          </div>
        </slot>
        <slot name="footer-wrapper">
          <div class="footer mt-6 px-6 pb-6">
            <slot name="footer"></slot>
          </div>
        </slot>
      </div>
    </Transition>
  </Portal>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal';
import PAlert from '@squirrel/components/p-alert/p-alert.vue';
import PCloseBtn from '@squirrel/components/p-close-btn/p-close-btn.vue';

export default {
  name: 'PDrawer',
  components: {
    PAlert,
    Portal,
    PCloseBtn,
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    appendTo: {
      type: String,
      default: 'body',
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'right',
    },
    zIndex: {
      type: Number,
      default: 900,
    },
    drawerStyle: {
      type: Object,
      default: () => ({}),
    },
    drawerClass: {
      type: String,
      default: 'drawer',
    },
    inClass: {
      type: String,
      default: '',
    },
    outClass: {
      type: String,
      default: '',
    },
    live: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    closeLabel: {
      type: String,
      default: 'Close',
    },
    outsideClickClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update', 'before-open', 'opening', 'opened', 'before-close', 'closing', 'closed'],
  data() {
    return {
      id: null,
      show: false,
      mount: false,
    };
  },
  computed: {
    transitionInClass() {
      return this.inClass || this.position === 'right' ? 'slideInRight' : 'slideInLeft';
    },
    transitionOutClass() {
      return this.outClass || this.position === 'right' ? 'slideOutRight' : 'slideOutLeft';
    },
  },
  created() {
    if (this.live) {
      this.mount = true;
    }
  },
  mounted() {
    this.id = 'drawer-' + this._uid;
    this.$watch(
      'value',
      function (newVal) {
        if (newVal) {
          this.mount = true;
          this.$nextTick(() => {
            this.show = true;
          });
        } else {
          this.show = false;
        }
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    close() {
      this.$emit('update', false);
    },
    beforeOpen() {
      this.$emit('before-open');
    },
    opening() {
      this.$emit('opening');
    },
    opened() {
      this.$emit('opened');
    },
    beforeClose() {
      this.$emit('before-close');
    },
    closing() {
      this.$emit('closing');
    },
    closed() {
      if (!this.live) {
        this.mount = false;
      }
      this.$nextTick(() => {
        this.$emit('closed');
      });
    },
  },
};
</script>

<style scoped lang="scss">
.drawer {
  @apply fixed bottom-0 top-0 flex flex-col bg-surface shadow-lg outline-none;
  width: 460px;
}
.drawer.drawer-left {
  @apply left-0;
}
.drawer.drawer-right {
  @apply right-0;
}

/* Slide from right animations */
@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.slideInRight {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: slideInRight;
}

@keyframes slideOutRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}
.slideOutRight {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: slideOutRight;
}

/* Slide from left animations */
@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.slideInLeft {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: slideInLeft;
}

@keyframes slideOutLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}
.slideOutLeft {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: slideOutLeft;
}
</style>
