import Vue from 'vue';
import { redirectToV2 } from '@/utils/redirect';

const DefaultLayout = () => import('@/settings/layouts/default-layout.vue');

Vue.component('LayoutsSettingsDefault', DefaultLayout);

export const settingsRoutes = [
  // Redirect all settings routes to v2
  {
    path: '/settings*',
    beforeEnter: (to) => {
      redirectToV2(to);
    },
  },
];
