import { api } from '@/axios/instance/api';

export const apiCreateApprovalChain = (payload) => api.post(`approval-chains`, payload);
export const apiDeleteApprovalChain = (id) => api.delete(`approval-chains/${id}`);
export const apiDeleteMultipleApprovalChains = (ids) => {
  const params = new URLSearchParams();
  ids.forEach((id) => params.append('chain_id[]', id));

  api.delete(`approval-chains/delete?${params}`);
};
export const apiFetchApprovalChains = () => api.get(`approval-chains`);
export const apiFetchChainMatchers = (params) => {
  return api.post('approval-chains/search', params);
};
export const apiFetchLinkApprovers = () => api.get(`link-approvers`);
export const apiFetchLinks = () => api.get(`links`);
export const fetchChainMatcherData = (chainIds) => {
  const params = { chain_id: chainIds };

  return api.get(`chain-matchers/chain-matcher-data`, { params });
};
