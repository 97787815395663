<template>
  <PDrawer
    :value="showHelpDrawer"
    drawer-class="drawer pb-0"
    title="Need Help?"
    @update="closeDrawer"
    @before-open="reset"
  >
    <template #content-wrapper>
      <div ref="container" class="h-full overflow-hidden">
        <HelpMain v-if="!formMode" :is-ticket-created="createdTicket" @form-mode-changed="formMode = $event" />
        <HelpForm
          v-else
          :mode="formMode"
          @cancel="ticketCreated(false)"
          @ticket-created="ticketCreated"
          @error="scrollTop"
        />
      </div>
    </template>
    <template #footer-wrapper>
      <div></div>
    </template>
  </PDrawer>
</template>

<script>
import PDrawer from '@squirrel/components/p-drawer/p-drawer.vue';
import { mapGetters } from 'vuex';
import HelpForm from '@/help/components/help-form.vue';
import HelpMain from '@/help/components/help-main.vue';

export default {
  name: 'HelpWidget',
  components: { HelpMain, HelpForm, PDrawer },
  data() {
    return {
      formMode: '',
      createdTicket: false,
    };
  },
  computed: {
    ...mapGetters('help', ['showHelpDrawer']),
  },
  methods: {
    closeDrawer() {
      this.$store.commit('help/toggleDrawer');
    },
    ticketCreated(isCreated) {
      this.createdTicket = isCreated;
      this.formMode = '';
    },
    reset() {
      this.formMode = '';
      this.createdTicket = false;
    },
    scrollTop() {
      this.$refs.container.scroll({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
.close-icon {
  @apply inline-block;
  width: 12px;
  height: 12px;
  background-image: url('@assets/drawer-close-icon.svg');
  background-size: 12px 12px;
  left: 24px;
  bottom: 34px;
}
</style>
