import { api } from '@/axios/instance/api';

export const apiCreateCandidate = (payload) => api.post(`candidates`, payload);
export const apiCreateLinkToken = () => api.post(`merge/create_link_token`);
export const apiCreateOffer = (payload) => api.post(`offers`, payload);
export const apiEditCandidate = (payload) => api.patch(`candidates/${payload.id}`, payload);
export const apiEditOffer = (payload) => api.patch(`offers/${payload.id}`, payload);
export const apiFetchCandidateJobName = (params) => api.get(`merge/get_candidate_job`, params);
export const apiFetchCustomFields = () => api.get(`gh-custom-fields?field_type=offer`);
export const apiFetchEquityVestScheduleTypes = () => api.get(`equity_vest_schedule_type`);
export const apiFetchIntegrations = () => api.get(`merge`);
export const apiFetchOfferApprovalChains = () => api.get(`offer-approval-chains`);
export const apiFetchOfferApprovalLinks = () => api.get(`offer-approval-links`);
export const apiFetchOfferActivity = (id) => api.get(`offers/${id}/activity`);
export const apiFetchPlaceholders = () => api.get(`templates/placeholders`);
export const apiFetchTemplates = () => api.get(`templates`);
export const apiGenerateOfferLetter = (payload) =>
  api.get(`templates/${payload.templateId}/offer-letter`, {
    params: { offerId: payload.offerId },
    responseType: 'arraybuffer',
  });

export const apiIntegrateWithDocusign = (payload) =>
  api.post(`docusign/integrations/authorize`, { code: payload.code });

export const apiRetrieveAccountToken = (params) => api.get(`merge/retrieve_account_token`, params);
export const apiSearchCandidates = (params) => api.get(`merge/search_candidates`, params);
export const apiSearchEmployees = (params) => api.get(`merge/search_employees`, params);
export const apiFetchFxRates = () => api.get('/employeesv2/fx-rates');
export const fetchCandidate = (id) => api.get(`candidates/${id}`);
export const fetchCandidates = (params) => api.get(`candidates`, params);
export const fetchOffer = (offerID) => api.get(`offers/${offerID}`);
export const fetchRange = (rangeID) => api.get(`ranges/${rangeID}`);
