<template>
  <div>
    <label class="mb-2 block text-sm font-bold">Attach files (optional)</label>
    <PAlert v-if="hasValidationError" class="mb-2" type="error"
      ><span class="text-xs font-bold">Failed to upload file. </span>
      <span class="font-medium"
        >We accept: DOC, DOCX, TXT, PDF, PNG, JPEG, JPG, ZIP, LOG, MOV, CSV, XLS, XLSX and files up to 50 MB in size.
        Please check your file type and try again.</span
      >
    </PAlert>
    <div>
      <div
        id="drop-zone"
        ref="drop-zone"
        class="h-[361px] overflow-y-auto rounded-2xl border border-dashed border-p-gray-30 p-8 pt-2"
        :class="{ 'flex items-center justify-center': !files.length || showOverlay }"
        @dragenter.prevent.stop="dragEnter"
        @dragover.prevent.stop="dragOver"
        @dragleave.prevent.stop="dragLeave"
        @drop.prevent.stop="drop"
      >
        <div :class="{ hidden: showOverlay }">
          <div
            v-for="(file, index) in files"
            :key="index"
            class="mb-4 flex items-center justify-between border-b border-dashed border-p-gray-30 py-4"
          >
            <p>{{ file.name }}</p>
            <i class="close-icon" @click="removeFile"></i>
          </div>
        </div>
        <div class="mt-8" :class="{ hidden: showOverlay }">
          <div>
            <input id="uploaded" class="hidden" type="file" multiple @change="fileInputChanged" />
          </div>
          <div class="text-center">
            <p class="text-sm font-semibold text-p-gray-40">Drag & drop a file to attach it, or</p>
            <label class="cursor-pointer text-sm font-semibold text-p-blue-60" for="uploaded"
              >browse for a file...</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PAlert from '@squirrel/components/p-alert/p-alert.vue';

export default {
  name: 'HelpFileuploadInput',
  components: { PAlert },
  emits: ['validated'],
  data() {
    return {
      showOverlay: false,
      hasValidationError: false,
      files: [],
      acceptedFileTypes: [
        '.png',
        '.jpg',
        '.jpeg',
        '.txt',
        '.csv',
        '.doc',
        '.docx',
        '.xls',
        '.xlsx',
        '.pdf',
        '.zip',
        '.log',
        '.mov',
      ],
      maximumFileSizeInBytes: 52428800,
    };
  },
  methods: {
    dragEnter(e) {
      this.addOverlayClass();
    },
    dragOver(e) {
      this.addOverlayClass();
    },
    dragLeave(e) {
      this.removeOverlayClass();
    },
    drop(e) {
      this.removeOverlayClass();
      const dt = e.dataTransfer;
      const files = dt.files;
      if (files && files.length) {
        this.processFiles(files);
      }
    },
    fileInputChanged(e) {
      const files = e.target.files;
      if (files && files.length) {
        this.processFiles(files);
      }
    },
    processFiles(files) {
      this.hasValidationError = false;
      const invalidFiles = [];
      for (const file of files) {
        if (this.isFileExtensionValid(file) && this.isFileSizeValid(file)) {
          this.files.push(file);
        } else {
          invalidFiles.push(file);
        }
      }
      this.hasValidationError = invalidFiles.length > 0;

      if (this.files.length) {
        this.$emit('validated', this.files);
      }
    },
    addOverlayClass() {
      this.$refs['drop-zone'].classList.add('overlay');
      this.showOverlay = true;
    },
    removeOverlayClass() {
      this.$refs['drop-zone'].classList.remove('overlay');
      this.showOverlay = false;
    },
    isFileExtensionValid(file) {
      const extension = this.getFileExtension(file.name);
      return this.acceptedFileTypes.includes(extension);
    },
    isFileSizeValid(file) {
      return file.size <= this.maximumFileSizeInBytes;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    getFileExtension(filename) {
      const index = filename.lastIndexOf('.');
      const extension = filename.substring(index);
      return extension ? extension.toLowerCase() : extension;
    },
  },
};
</script>

<style scoped lang="scss">
#drop-zone.overlay {
  position: relative;
  z-index: 1;
  border: 2px solid #0e1116;
  background-image: url('@/help/assets/drop-zone-overlay.svg');
  background-repeat: no-repeat;
  background-position: center;
}
#drop-zone .close-icon {
  width: 16px;
  height: 16px;
  background-image: url('@/help/assets/close-outline-icon.svg');
  background-size: 16px 16px;
  cursor: pointer;
}
#drop-zone .close-icon:hover {
  background-image: url('@/help/assets/close-outline-hover-icon.svg');
}
</style>
