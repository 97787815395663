export const PAGE_SIZE = 20;

export const ACCESS_TOKEN = 'access_token';
export const ACCESS_COOKIE_NAME = 'X-Authorization';
export const REFRESH_TOKEN = 'refresh_token';
export const TOKENS_EXPIRY_ERROR = 'All tokens expired';
export const TOKENS_INVALID_ERROR = 'Tokens are invalid';
export const REFRESH_ROUTE = '/token/access/';

export const NAVBAR_SPINNER_SIZE = 20;

export const DATE_OPTIONS = { year: 'numeric', month: 'long', day: 'numeric' };
