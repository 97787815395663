const state = {
  isLoading: false,
  loadingText: 'Loading...',
};

const getters = {
  getIsLoading: (state) => state.isLoading,
  getLoadingText: (state) => state.loadingText,
};

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setLoadingText(state, loadingText) {
    state.loadingText = loadingText;
  },
};

const actions = {
  setIsLoading({ commit }, isLoading) {
    commit('setIsLoading', isLoading);
  },
  setLoadingText({ commit }, loadingText) {
    commit('setLoadingText', loadingText);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
