import axios from 'axios';
import { useTokenInterceptors } from '@/axios/interceptors';
import { API_URL } from '@/helpers';

const base = axios.create({
  baseURL: API_URL,
});

useTokenInterceptors(base);

/**
 * A function to simplify response and error handling for Axios requests
 * @param {Promise | () => Promise} axiosFn - An Axios promise or a function that returns an Axios promise
 * @param {any} defaultData - The default data to return if there is no data from the response or in case there's an error
 * @returns {{data: any, response: object, error: object }} - Returns an object that includes the response data, the response itself and the error
 */
const useResponse = (axiosFn, defaultData) => {
  const promise = axiosFn instanceof Promise ? axiosFn : axiosFn();

  return Promise.resolve(promise)
    .then((response) => {
      const data = response.data ?? defaultData ?? {};
      return { data, response, error: null };
    })
    .catch((error) => {
      return { data: defaultData ?? {}, response: {}, error };
    });
};

const AXIOS_METHODS = ['request', 'get', 'delete', 'head', 'options', 'post', 'put', 'patch'];

/**
 * This creates an object with all the Axios methods enhanced with `useResponse`.
 */
const api = AXIOS_METHODS.reduce((acc, axiosMethod) => {
  const fn = (...args) => useResponse(base[axiosMethod](...args));

  return { ...acc, ...{ [axiosMethod]: fn } };
}, {});

export { api, base, useResponse };
