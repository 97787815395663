import { trim } from 'lodash-es';

export const createRedirectToV2Url = (to) => {
  const query = to && to.query ? '?' + new URLSearchParams(to.query).toString() : '';
  const path = to && to.path ? `${trim(to.path, '/')}` : '';
  const hash = to && to.hash ? `#${trim(to.hash, '#')}` : '';
  const location =
    process.env.NODE_ENV === 'development'
      ? `${window.location.protocol}//${window.location.hostname}:5173`
      : window.location.origin;

  return `${location}/v2/${path}${query}${hash}`;
};

/**
 * This function redirects the user to the v2 version of the app.
 * It uses a similar signature to the vue-router's `router.push()` function but it accepts only `path` and not `name` or `params`.
 * Example usage: `redirectToV2({ path: 'ccpv2-list' })`
 */
export const redirectToV2 = (to) => {
  window.location.href = createRedirectToV2Url(to);
};
