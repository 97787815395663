import axios from 'axios';
import { apiCommentOnOffer, apiDeleteComment } from '@/offers/api/dashboard';
import { apiFetchOfferActivity } from '@/offers/api/offers-common';

const state = {
  offerActivities: [],
  isLoading: false,
  offerActivityCursor: null, // Check our DRF Cursor pagination for more info
};

const getters = {
  offerActivities: (state) => state.offerActivities,
  isLoading: (state) => state.isLoading,
  offerActivityCursor: (state) => state.offerActivityCursor,
};

const mutations = {
  setOfferActivity(state, activities) {
    state.offerActivities = [...activities];
  },
  setIsLoading(state, loading) {
    state.isLoading = loading;
  },
  setOfferActivityCursor(state, cursor) {
    state.offerActivityCursor = cursor;
  },
};

const actions = {
  async fetchOfferActivity({ commit }, originalOfferId) {
    if (!originalOfferId) {
      return;
    }

    try {
      commit('setIsLoading', true);
      commit('setOfferActivity', []);
      commit('setOfferActivityCursor', null);
      const { data } = await apiFetchOfferActivity(originalOfferId);
      commit('setOfferActivity', data.results);
      commit('setOfferActivityCursor', data.next);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setIsLoading', false);
    }
  },
  async mergePaginatedOfferActivity({ commit, state }, originalOfferId) {
    if (!originalOfferId) {
      return;
    }

    try {
      commit('setIsLoading', true);
      const { data } = await axios.get(state.offerActivityCursor);
      const newOfferActivity = [...state.offerActivities, ...data.results];
      commit('setOfferActivity', newOfferActivity);
      commit('setOfferActivityCursor', data.next);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setIsLoading', false);
    }
  },
  async commentOnOffer({ commit, state }, opts) {
    try {
      commit('setIsLoading', true);
      const { data: offerActivity } = await apiCommentOnOffer(opts.offerId, opts.payload);
      commit('setOfferActivity', [offerActivity, ...state.offerActivities]);
      commit('setIsLoading', false);
      opts.onSuccess();
    } catch (error) {
      commit('setIsLoading', false);
      opts.onError();
    }
  },
  async deleteComment({ commit, state }, opts) {
    try {
      commit('setIsLoading', true);
      await apiDeleteComment(opts.id);
      const filteredActivity = [...state.offerActivities].filter((oa) => oa.id !== opts.id);
      commit('setOfferActivity', filteredActivity);
      commit('setIsLoading', false);
      opts.onSuccess();
    } catch (error) {
      commit('setIsLoading', false);
      opts.onError();
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
