import axios from 'axios';
import { URL_BASE } from '@/helpers';

const ATS_MAPPING_OPTIONS_API = '/api/ats-mapping-options';

const state = {
  atsMappingOptions: [],
};

const getters = {
  atsMappingOptions: (state) => state.atsMappingOptions,
};

const mutations = {
  setAtsMappingOptions(state, mappings) {
    state.atsMappingOptions = [...mappings];
  },
};

const actions = {
  async fetchAtsMappingOptions({ commit }) {
    try {
      const { data } = await axios.get(URL_BASE + ATS_MAPPING_OPTIONS_API);
      commit('setAtsMappingOptions', data);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
