import '@assets/main.css';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import FloatingVue from 'floating-vue';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VModal from 'vue-js-modal';
import TextHighlight from 'vue-text-highlight';
import Toast, { POSITION } from 'vue-toastification';
import App from '@/App.vue';
import { useTokenInterceptors } from '@/axios/interceptors';
import { DEBUG } from '@/helpers';
import { router } from '@/router';
import store from '@/store';

Vue.config.productionTip = false;

if (DEBUG) {
  // Adds the ability to use console.log() within template expressions
  // Example `<p>{{ $log('hello') }}</p>`
  Vue.prototype.$log = console.log;

  // We are in dev mode
  // Add helper to trace vue components
  window.vueComponentTrace = window.vueTrace = (selector) => {
    const trace = (acc, el) => {
      const name = el?.tagName;
      const options = el?.$options || el?.__vue__?.$options;
      const compName = options?.name;
      return name || compName ? trace([compName || name, ...acc], options?.parent || el.parentNode) : acc;
    };

    // See https://willd.me/posts/0-in-chrome-dev-tools
    // eslint-disable-next-line no-undef
    const el = selector ? document.querySelector(selector) : $0;
    return trace([], el).join(' > ');
  };
}

Vue.use(VModal);

Vue.use(Toast, {
  position: POSITION.BOTTOM_CENTER,
  bodyClassName: ['p-toast-body'],
  hideProgressBar: true,
  icon: false,
  transition: 'Vue-Toastification__fade',
  transitionDuration: {
    enter: 500,
    leave: 250,
  },
});

Vue.component('TextHighlight', TextHighlight);

Vue.use(VueClipboard);

Vue.use(FloatingVue);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        stickySession: true,
        // https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/configuration/#mutation-limits
        mutationBreadcrumbLimit: 500,
        mutationLimit: 1500,
      }),
    ],
    environment: process.env.VUE_APP_SENTRY_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'All tokens expired',
      /Loading CSS chunk \d+ failed\./, // Regular expression with \d+ to match any number
      /Loading chunk \d+ failed\./,
    ],
  });
}

useTokenInterceptors(axios);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
