import { fetchRange } from '@/offers/api/offers-common';

const state = {
  range: {},
  rangeGeo: null,
};

const getters = {
  getRange: (state) => {
    return state.range;
  },
  getRangeGeo: (state) => {
    return state.range.co_geo || state.rangeGeo;
  },
};

const mutations = {
  setRange(state, range) {
    state.range = range;
    if (range && Object.keys(range).length > 0) {
      // Sometimes we use "{}" instead of null, thus the "keys" check
      state.rangeGeo = range.co_geo;
    }
  },
  setRangeGeo(state, rangeGeo) {
    state.rangeGeo = rangeGeo;
  },
};

const actions = {
  getRange(context, rangeId) {
    fetchRange(rangeId).then((response) => {
      context.commit('setRange', response.data);
    });
  },
  setRange({ commit }, range) {
    commit('setRange', range);
  },
  setRangeGeo(context, rangeGeo) {
    // XXX: This is redundant! We already have the mutation!
    context.commit('setRangeGeo', rangeGeo);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
