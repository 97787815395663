import { api } from '@/axios/instance/api';

export const apiCreateTemplate = (formData) =>
  api.post(`templates`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const apiDeleteTemplate = (id) => api.delete(`templates/${id}`);
export const apiFetchTemplate = (id) => api.get(`templates/${id}`);
export const apiUpdateTemplate = (id, formData) =>
  api.put(`templates/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
