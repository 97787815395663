import axios from 'axios';
import { URL_BASE } from '@/helpers';

const SALARY_API = `${URL_BASE}/api/salary-board`;

const state = {
  salaryBoardDetails: {},
};

const getters = {
  salaryBoardDetails: (state) => {
    return state.salaryBoardDetails;
  },
};

const mutations = {
  setSalaryBoardDetails(state, salaryBoardDetails) {
    state.salaryBoardDetails = salaryBoardDetails;
  },
};

const actions = {
  async fetchSalaryBoardDetails({ commit }, company_id = 1) {
    // OFFERS-323 Set default company_id to 1 assuming only single company
    try {
      const url = `${SALARY_API}/company-salary-board-details/${company_id}`;
      const response = await axios.get(url);
      commit('setSalaryBoardDetails', response.data);
    } catch (error) {
      commit('setSalaryBoardDetails', {});
      console.log(error);
    }
  },
  async addSalaryBoardDetails({ context }, payload) {
    try {
      const url = `${SALARY_API}`;

      // TODO: Fix this the next time the file is edited.

      await axios.post(url, payload);
      payload.onSuccess();
    } catch (error) {
      const errors = error.response.data?.errors;
      const errorMessage = errors?.join('\n');
      payload.onError(errorMessage);
    }
  },
  async editSalaryBoardDetails({ context }, payload) {
    try {
      const url = `${SALARY_API}`;

      // TODO: Fix this the next time the file is edited.

      await axios.put(url + `/${payload.id}`, payload);
      payload.onSuccess();
    } catch (error) {
      const errors = error.response.data?.errors;
      const errorMessage = errors?.join('\n');
      payload.onError(errorMessage);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
