const normalizeUrl = (url) => {
  if (url.indexOf('//') === 0) {
    url = location.protocol + url;
  }

  return url;
};

const isValidUrl = (url) => {
  url = normalizeUrl(url);

  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const isExternalLink = function (url) {
  url = String(url);

  return isValidUrl(url) && (url.indexOf(':') > -1 || url.indexOf('//') > -1);
};
