/**
 * Sets value to zero if not a valid number.
 * Valid only in the context of CompetingOfferHelpers helper.
 * @param {*} value Value to be handled
 * @returns {number} Value or zero
 */
const toNum = (value) => (isNaN(value) ? 0 : +value);

export default class CompetingOfferHelpers {
  /**
   * Calculates the competing offer's total annual comensation.
   * @param {object} offer Competing offer to be sumarized
   * @returns {number} A sum of offer's values that were provided: salary, equity, bonus and signOn
   */
  static getTotalComp(offer) {
    let [salary, equity, bonus, signOn] = [offer.salary, offer.equity, offer.bonus, offer.sign_on].map((value) =>
      toNum(value)
    );
    bonus = offer.bonus_type === 'currency' ? bonus : (bonus * salary) / 100;
    return salary + equity + bonus + signOn;
  }
}
