export const playgroundRoutes = [
  ...(process.env.NODE_ENV === 'development' || process.env.VUE_APP_IS_TEAM_BRANCH
    ? [
        {
          path: '/playground/:page?',
          name: 'playground',
          component: () => import('@/playground/pages/playground.vue'),
        },
      ]
    : []),
];
