<template>
  <a v-if="typeof $attrs.to === 'string' && isExternalLink($attrs.to)" :href="sanitizeUrl($attrs.to)" target="_blank">
    <slot></slot>
  </a>
  <RouterLink v-else v-bind="$attrs">
    <slot></slot>
  </RouterLink>
</template>

<script>
import { isExternalLink } from '@/squirrel/utils/link';
import { sanitizeUrl } from '@/squirrel/utils/sanitization';

export default {
  name: 'PLink',
  methods: {
    isExternalLink,
    sanitizeUrl,
  },
};
</script>
