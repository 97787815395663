/* The state of the offer filter component */
import { api } from '@/axios/instance/api';
import { getOfferList } from '@/store/services/offers';

const state = {
  selectedOfferStatus: null,
  offers: [],
  offersNextPageUrl: null,
  areOffersLoading: false,
  areMoreOffersLoading: false,
};

const getters = {
  selectedOfferStatus: (state) => state.selectedOfferStatus,
  offers: (state) => state.offers,
  areOffersLoading: (state) => state.areOffersLoading,
  areMoreOffersLoading: (state) => state.areMoreOffersLoading,
  hasMoreOffers: (state) => !!state.offersNextPageUrl,
};

const mutations = {
  setSelectedOfferStatus(state, status) {
    state.selectedOfferStatus = status;
  },
  setOffersLoading(state, areOffersLoading) {
    state.areOffersLoading = areOffersLoading;
    if (areOffersLoading) {
      state.offers = [];
      state.offersNextPageUrl = null;
    }
  },
  setMoreOffersLoading(state, areMoreOffersLoading) {
    state.areMoreOffersLoading = areMoreOffersLoading;
  },
  setOffers(state, offers) {
    state.offers = offers;
  },
  setOffersNextPageUrl(state, url) {
    state.offersNextPageUrl = url;
  },
  removeOffer(state, offerId) {
    // XXX We'd most likely want to just refresh the offer list instead of this
    state.offers = state.offers.filter((o) => o.id !== offerId);
  },
};

const actions = {
  async fetchOffers({ state, commit }, searchKeyword) {
    if (!state.selectedOfferStatus && !searchKeyword) {
      return;
    }

    commit('setOffersLoading', true);

    const { data, error } = await getOfferList(state.selectedOfferStatus, searchKeyword);

    if (!error) {
      commit('setOffers', data.results);
      commit('setOffersNextPageUrl', data.next);
    } else {
      console.error('Failed to fetch offer list!', error);
    }

    commit('setOffersLoading', false);
  },
  async fetchMoreOffers({ state, commit }) {
    commit('setMoreOffersLoading', true);
    const { data, error } = await api.get(state.offersNextPageUrl);

    if (!error) {
      commit('setOffers', [...state.offers, ...data.results]);
      commit('setOffersNextPageUrl', data.next);
    } else {
      console.error('Failed to fetch the next page of offer list!', error);
    }

    commit('setMoreOffersLoading', false);
  },
};

export default { namespaced: true, state, getters, mutations, actions };
