import { apiFetchCompetingOffers } from '@/offers/api/competing-offers-dashboard';

const state = {
  competingOffers: [],
  isLoadingCompetingOffers: false,
  count: 0,
};

const getters = {
  competingOffers: (state) => state.competingOffers,
  competingOffersWithCellData: (state) =>
    state.competingOffers.map((competingOffer) => {
      return {
        ...competingOffer,
        offer_link: {
          link: 'offer',
          link_params: {
            id: competingOffer.offer_id,
          },
          link_href: `/dashboard/offer/${competingOffer.offer_id}`,
          link_display_content: `${competingOffer.offer_id} - ${competingOffer.candidate_name}`,
        },
        candidate_name: competingOffer.candidate_name,
      };
    }),
  isLoadingCompetingOffers: (state) => state.isLoadingCompetingOffers,
  count: (state) => state.count,
};

const mutations = {
  setCompetingOffers(state, competingOffers) {
    state.competingOffers = competingOffers;
  },
  setIsLoadingCompetingOffers(state, isLoadingCompetingOffers) {
    state.isLoadingCompetingOffers = isLoadingCompetingOffers;
  },
  setCount(state, count) {
    state.count = count;
  },
};

const actions = {
  async fetchCompetingOffers(context, parameters) {
    const { commit } = context;
    try {
      commit('setIsLoadingCompetingOffers', true);
      commit('setCompetingOffers', []);
      const { data } = await apiFetchCompetingOffers(parameters);
      commit('setCompetingOffers', data.results);
      commit('setCount', data.count);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setIsLoadingCompetingOffers', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
