export const USER_PERMISSIONS = {
  ADMIN: 'admins',
  APPROVER: 'approvers',
  SUBMITTER: 'submitters',
  SUBMITTERAPPROVER: 'submitterapprovers',
  TEAM_LEAD: 'teamleads',
  EMPLOYEE: 'employees',
};

export const OFFER_STATUS = {
  DRAFT: 'draft',
  PENDING: 'pending',
  APPROVED: 'approved',
};

export const OFFER_ACTIVITY_STATUS = {
  SENT_FOR_APPROVAL: 0,
  APPROVED: 1,
  REJECTED: 2,
  ALL_APPROVED: 3,
  ALL_REJECTED: 4,
  COMMENT: 5,
};

export const ORDER_DIRECTION = {
  DESC: 0,
  ASC: 1,
};

export const TABLE_CELL_TYPE = {
  TEXT: 0,
  BOOLEAN: 1,
  LINK: 2,
  NUMBER: 3,
  ENUMERATION: 4,
  ICON_CARD: 5,
  SELECT: 6,
  CLICKABLE: 7,
  INPUT: 8,
};

export const VALUE_TYPE = {
  INT: 'int',
  STR: 'str',
  GUID: 'guid',
  FLOAT: 'float',
  BOOLEAN: 'bool',
};

export const FILTER_TYPE = {
  CHECKBOX: 1,
  CHIPS_SEARCH: 2,
  SELECT_OBJECT: 3,
};

export const BONUS_TYPE = {
  percentage: 'Percentage',
  currency: 'Currency',
};
